// Copyright (C) 2021 Deconve Technology. All rights reserved.

import axios, { AxiosRequestConfig } from 'axios';
import store from '../../store';

interface TagRequest {
  workspaceUserId: string;
  tagId: string;
}

interface UserTag {
  id: string;
}

export function addWorkspaceUserTag({ workspaceUserId, tagId }: TagRequest): Promise<string> {
  return new Promise((resolve) => {
    const { authorizationToken } = store.getters;

    const requestOptions: AxiosRequestConfig = {
      baseURL: process.env.VUE_APP_DECONVE_AUTH_URL,
      url: `/users/${workspaceUserId}/tags/`,
      method: 'POST',
      data: {
        // eslint-disable-next-line @typescript-eslint/camelcase
        tag_id: tagId,
      },
      headers: {
        Authorization: authorizationToken,
      },
    };

    axios(requestOptions).then((response) => {
      const { data } = response;

      resolve(data);
    });
  });
}

export function removeWorkspaceUserTag({ workspaceUserId, tagId }: TagRequest): Promise<void> {
  return new Promise((resolve) => {
    const { authorizationToken } = store.getters;

    const requestOptions: AxiosRequestConfig = {
      baseURL: process.env.VUE_APP_DECONVE_AUTH_URL,
      url: `/users/${workspaceUserId}/tags/${tagId}/`,
      method: 'DELETE',
      headers: {
        Authorization: authorizationToken,
      },
    };

    axios(requestOptions).then(() => {
      resolve();
    });
  });
}

export function getWorkspaceUserTags(id: string): Promise<UserTag[]> {
  return new Promise((resolve) => {
    const { authorizationToken } = store.getters;

    const requestOptions: AxiosRequestConfig = {
      baseURL: process.env.VUE_APP_DECONVE_AUTH_URL,
      url: `/users/${id}/tags/`,
      method: 'GET',
      headers: {
        Authorization: authorizationToken,
      },
    };

    axios(requestOptions).then((response) => {
      const { data } = response;

      resolve(data);
    });
  });
}
